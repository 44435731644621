<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <app-text-search-field
          @input="handleSearchInput($event)"
          @filter="showFilter = !showFilter"
        />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          @click="handleNewCustomer()"
          v-if="$acl.can('customerCreateEdit')"
        >
          Novo
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="showFilter">
      <v-row>
        <v-col cols="12" md="4">
          <app-text-field
            clearable
            autofocus
            solo
            flat
            v-debounce:300ms="select"
            background-color="grey lighten-4"
            v-model="searchParams.address"
            placeholder="Endereço"
            @click:clear="handleClearAddress()"
          />
        </v-col>
        <v-col cols="12" md="4">
          <app-text-field
            clearable
            autofocus
            solo
            flat
            v-debounce:300ms="select"
            background-color="grey lighten-4"
            v-model="searchParams.district"
            placeholder="Bairro"
            @click:clear="handleClearDistrict()"
          />
        </v-col>
        <v-col cols="12" md="4">
          <app-text-field
            placeholder="Cidade"
            readonly
            :value="searchParams.city"
            @click="handleCitySearch()"
            clearable
            autofocus
            solo
            flat
            background-color="grey lighten-4"
            @click:clear="handleClearCity()"
            prepend-inner-icon="mdi-magnify"
          />
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="customers.data"
          :items-per-page="20"
          :loading="loading"
          :options.sync="options"
          :server-items-length="customers.total"
          @update:options="handleTableUpdate($event)"
          @click:row="handleNewCustomer($event.id)"
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.name`]="{ item }">
            <CustomerLabel :customer="item" :hideLink="true" />
            <small v-if="item.main_address">
              <b>Endereço: </b>
              {{ item.main_address.address }}, {{ item.main_address.number }} -
              {{ item.main_address.city }}
            </small>
          </template>
          <template v-slot:[`item.doc`]="{ item }">
            <span v-if="item.person_type == 'fisica'">
              {{ $format.cpf(item.cpf) }}
            </span>
            <span v-if="item.person_type == 'juridica'">
              {{ $format.cnpj(item.cnpj) }}
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  @click="handleCreditAnalysis(item.id)"
                  v-if="$acl.can('customerCreditAnalysis')"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-cash-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Analise de Crédito</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <app-pagination :data="customers" @click="paginate($event)" />
      </v-col>
    </v-row>
    <StoreCustomerForm @store="select()" ref="StoreCustomerForm" />
    <CustomerCreditAnalysisDialog
      @store="select()"
      ref="CustomerCreditAnalysisDialog"
    />
    <SearchCity
      @input="setCity($event)"
      ref="SearchCity"
      :item-value="(val) => val.city"
    />
  </div>
</template>

<script>
import StoreCustomerForm from "@/components/customer/forms/StoreCustomerForm.vue";
import CustomerCreditAnalysisDialog from "@/components/customer/sections/CustomerCreditAnalysisDialog.vue";
import CustomerIcon from "@/components/customer/ui/CustomerIcon.vue";
import CustomerLabel from "@/components/customer/sections/CustomerLabel.vue";
import SearchCity from "@/components/app/forms/SearchCity";

export default {
  components: {
    StoreCustomerForm,
    CustomerCreditAnalysisDialog,
    SearchCity,

    CustomerIcon,
    CustomerLabel,
  },

  data: () => ({
    showFilter: false,
    headers: [
      { text: "Name", align: "start", value: "name" },
      { text: "Tipo de pessoa", align: "center", value: "person_type" },
      { text: "CNPJ/CPF", align: "center", value: "doc" },
      { text: "Status", align: "center", value: "status" },
      { text: " ", align: "end", value: "action", width: "1px" },
    ],

    options: {},

    loading: false,

    searchParams: {
      page: 1,
      orderBy: "name",
      orderDirection: "asc",
    },

    customers: {
      data: [],
    },
  }),

  created() {
    this.select();
  },

  methods: {
    select() {
      this.$http
        .index("customer/customer", this.searchParams)
        .then((response) => {
          this.customers = response.customers;
        });
    },

    paginate(page) {
      this.searchParams.page = page;
      this.select();
    },

    handleTableUpdate(options) {
      this.options = options;
      //this.searchParams.page = options.page
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      this.select(options.page);
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleNewCustomer(id = null) {
      this.$refs.StoreCustomerForm.open(id);
    },
    handleCreditAnalysis(customer_id) {
      this.$refs.CustomerCreditAnalysisDialog.open(customer_id);
    },

    handleConsultInstallment(customer_id) {
      this.$router.push({
        name: "consultInstallmentIndex",
        params: { id: customer_id },
      });
    },
    handleCitySearch() {
      this.$refs.SearchCity.open();
    },
    setCity(city) {
      this.searchParams.city = city ? city : null;
      this.select();
    },
    handleClearCity() {
      this.searchParams.city = null;
      this.select();
    },
    handleClearAddress() {
      this.searchParams.address = null;
      this.select();
    },
    handleClearDistrict() {
      this.searchParams.district = null;
      this.select();
    },
  },
};
</script>

<style>
</style>